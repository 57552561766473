
<template>
    <div
        id="message"
        style="width: 80%; z-index: 9999; position: fixed;left: 50%; transform: translateX(-50%)"
        v-bind:class="{ no_display: messageInfo.hide }"
    >
        <!--<div class="spinner-border text-success" role="status">
            <span class="sr-only">Loading...</span>
        </div>-->
        <div style="max-width: 70%; margin: 30px auto; text-align: center">
            <div
                class="alert alert-info"
                role="alert"
                style="
                    display: inline-block;
                    width: 100%;
                    word-break: break-word;
                    color: red;
                    padding: 0.5em 10px;
                "
            >
                <i class="glyphicon glyphicon-warning-sign"></i>
                <span style="margin-left: 10px">{{
                    messageInfo.errorInfo
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Message",
    data() {
        return {
            messageInfo: {
                hide: true,
                errorInfo: "",
            },
        };
    },
    mounted() {
        this.$vc.on("message_openMessage", (_msg) => {
            //console.log("开始加载");
            this.messageInfo.hide = false;
            this.messageInfo.errorInfo = _msg.msg;
            this.messageTimer();
        });
        // this.$vc.on(this.$route.path, 'clear_timer', 'message', () =>{
        //     this.messageTimer();
        // });
    },
    methods: {
        //验证码定时
        messageTimer() {
            var num = 5;
            var _timer = this.$vc.createTimer(() => {
                num--;
                if (num === 1) {
                    this.$vc.clearTimer(_timer);
                    this.messageInfo.hide = true;
                }
            }, 1000);
        },
    },
};
</script>
